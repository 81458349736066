<template>
	<gc-dialog
		:title="currentRow.title"
		:show.sync="visible"
		@close="$emit('close-modal')"
		@cancel="$emit('close-modal')"
		width="1320px"
		:showFooter="false"
	>
		<div class="content" style="height: 550px" v-loading="loading">
			<gc-table
				border
				showPage
				:total="total"
				:columns="columns"
				:table-data="tableData"
				:current-page.sync="currentPage"
				:page-size="pageSize"
				@size-change="handleSizeChange"
				@current-page-change="handlePageChange"
			></gc-table>
		</div>
	</gc-dialog>
</template>

<script>
import { apiGetWaterEquipmentList } from '@/apis/home.api'

export default {
	props: {
		currentRow: {
			type: Object,
			default: () => {
				return {}
			},
		},
		tabList: {
			type: Array,
			default: () => {
				return [
					{
						name: '工商表',
						value: 0,
					},
				]
			},
		},
	},
	data() {
		return {
			loading: false,
			visible: true,
			total: 1,
			columns: [
				{ key: 'userNo', name: '用户号' },
				{ key: 'deviceNo', name: '水表号' },
				{ key: 'username', name: '用户名称' },
				{ key: 'addressName', name: '用户地址' },
				{ key: 'waterRegionName', name: '所属区域' },
				{ key: 'waterType', name: '水表类型' },
				{ key: 'lastCollectionTime', name: '上次采集时间' },
				{ key: 'lastCollectionReading', name: '当前读数' },
			],
			tableData: [],
			currentPage: 1,
			pageSize: 20,
		}
	},
	created() {
		this.getList(1)
	},

	methods: {
		handleSizeChange(size) {
			this.pageSize = size
			this.getList(1)
		},
		handlePageChange(current) {
			this.getList(current)
		},
		getList(current) {
			this.loading = true
			this.currentPage = current
			const { waterRegionCode, userType, queryDimension } = this.currentRow
			apiGetWaterEquipmentList({
				waterRegionCode,
				userType,
				queryDimension,
				current,
				size: this.pageSize,
			})
				.then(res => {
					this.total = Number(res.total)
					this.tableData = res.records || []
				})
				.finally(() => {
					this.loading = false
				})
		},
	},
}
</script>
<style lang="scss" scoped>
.content {
	display: flex;
	flex-direction: column;
	.tabs-box {
		display: flex;
		position: relative;
		.tab-item {
			font-size: 16px;
			color: #7b7e97;
			margin-right: 24px;
			line-height: 28px;
			&.active {
				color: #4d6bff;
			}
		}
		.underline {
			position: absolute;
			bottom: 0;
			width: 65px;
			height: 3px;
			background: linear-gradient(90deg, #4d6bff 0%, rgba(77, 107, 255, 0) 100%);
		}
	}
	.gc-table {
		padding-top: 12px;
		flex: 1;
		height: 0;
	}
}
</style>
