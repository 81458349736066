<template>
	<div class="water-home-meter-line">
		<div class="modal">
			<div class="water-home-title">小表抄见率</div>
			<div class="rate-chart-line chart" v-if="meterReadingRate.length > 0"></div>
			<gc-empty v-else />
		</div>
		<div class="modal">
			<div class="water-home-title">工商表水量</div>
			<div class="water-volume-chart-line chart" v-if="businessWater.length > 0"></div>
			<gc-empty v-else />
		</div>
	</div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill'
import { init, graphic } from 'echarts'
import { isBlank } from '@/utils/validate'
import { apiGetSmallWatchRate, apiGetBusinessWater } from '@/apis/home.api'

export default {
	props: {
		waterRegionCode: {
			type: [String, Number],
			default: '',
		},
	},
	data() {
		return {
			rateInstance: null,
			volumeInstance: null,
			meterReadingRate: [],
			businessWater: [],
		}
	},

	watch: {
		waterRegionCode(val) {
			if (!isBlank(val)) {
				this.getMeterReadingRate()
				this.getIndustryMonthUsed()
			}
		},
	},
	mounted() {
		this.$nextTick(() => {
			const resizeOb = new ResizeObserver(() => {
				this.rateInstance && this.rateInstance.resize()
				this.volumeInstance && this.volumeInstance.resize()
			})
			resizeOb.observe(document.querySelector('.water-home-meter-line'))
		})
	},

	methods: {
		getMeterReadingRate() {
			apiGetSmallWatchRate({
				waterRegionCode: this.waterRegionCode,
			}).then(res => {
				this.meterReadingRate = res || []
				if (this.meterReadingRate.length > 0) {
					this.$nextTick(() => {
						if (!this.rateInstance) {
							this.rateInstance = init(document.querySelector('.rate-chart-line'))
						}
						this.rateInstance.setOption(
							this.packageLineOptions({ color: '#3BBE40', name: '小表抄见率' }, 'smallWatch'),
						)
					})
				} else {
					this.rateInstance = null
				}
			})
		},
		getIndustryMonthUsed() {
			apiGetBusinessWater({
				waterRegionCode: this.waterRegionCode,
			}).then(res => {
				this.businessWater = res || []
				if (this.businessWater.length > 0) {
					this.$nextTick(() => {
						if (!this.volumeInstance) {
							this.volumeInstance = init(document.querySelector('.water-volume-chart-line'))
						}
						this.volumeInstance.setOption(
							this.packageLineOptions(
								{
									color: '#4D6BFF',
									name: '工商表水量',
									unit: 'm³',
								},
								'businessWatch',
							),
						)
					})
				} else {
					this.volumeInstance = null
				}
			})
		},

		packageLineOptions(options, flag) {
			const { color, name, unit } = options
			let xData = []
			let yData = []
			if (flag === 'smallWatch') {
				// 小表抄见率
				xData = this.meterReadingRate.map(item => item.date)
				yData = this.meterReadingRate.map(item => item.readingRate)
			} else {
				xData = this.businessWater.map(item => item.yearMonth)
				yData = this.businessWater.map(item => item.amount)
			}

			return {
				color: [color],
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						label: {
							backgroundColor: '#6a7985',
						},
					},
				},
				legend: {
					icon: 'roundRect',
					itemWidth: 12,
					itemHeight: 12,
					textStyle: {
						color: '#000',
						fontSize: 10,
					},
					right: '2%',
				},
				grid: {
					left: '3%',
					right: '3%',
					bottom: '3%',
					top: '18%',
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						axisLine: { show: false },
						axisTick: { show: false },
						axisLabel: {
							color: '#666666',
							fontSize: 12,
						},
						boundaryGap: false,
						data: xData,
					},
				],
				yAxis: [
					{
						name: unit ? `单位: ${unit}` : null,
						type: 'value',
					},
				],
				series: [
					{
						name,
						type: 'line',
						areaStyle: {
							color: new graphic.LinearGradient(0, 0, 0, 1, [
								{
									offset: 0,
									color: `${color}60`,
								},
								{
									offset: 1,
									color: `${color}00`,
								},
							]),
						},
						lineStyle: {
							width: 4,
							color: new graphic.LinearGradient(0, 0, 1, 1, [
								{
									offset: 0,
									color: `${color}02`,
								},
								{
									offset: 0.15,
									color: `${color}`,
								},
								{
									offset: 0.85,
									color: `${color}`,
								},
								{
									offset: 1,
									color: `${color}02`,
								},
							]),
						},
						showSymbol: false,
						smooth: true,
						data: yData,
					},
				],
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.water-home-meter-line {
	background-color: #f2f4fc;
	padding: 12px;
	border-radius: 12px;
	margin-top: 16px;
	.modal {
		.chart {
			width: 100%;
			height: 245px;
		}
		& + .modal {
			margin-top: 12px;
		}
	}
}

@media screen and (min-width: 600px) and (max-width: 1400px) {
	.water-home-meter-line {
		margin-top: 16px;
		.modal {
			.chart {
				width: 100%;
				height: 175px;
			}
			& + .modal {
				margin-top: 8px;
			}
		}
	}
}
</style>
