<template>
	<div class="water-home-page">
		<div class="top-region-pick">
			<gc-custom-search
				v-if="treeOptions.length"
				class="search"
				key-word="所属区域"
				type="tree-select"
				width="380px"
				show-checkbox
				:search.sync="ssqy"
				:searchOption="treeOptions"
				:propsObj="{
					label: 'name',
					children: 'children',
					id: 'code',
					disabled: disabledFn,
				}"
			></gc-custom-search>
		</div>
		<div class="content">
			<div class="left-modal modal">
				<div class="statistic-card">
					<div class="left-icon">
						<img src="@/assets/imgs/icon/yuanchuandabiao.svg" alt="" />
						<p class="cn-name">远传大表</p>
						<p class="en-name">The big watch</p>
					</div>
					<div class="right-data">
						<div
							class="label-item"
							@click="
								handleNumClick({
									userType: 4,
									queryDimension: 0,
									title: '工商表',
								})
							"
						>
							<div class="title">工商表数量</div>
							<div class="value">{{ bigWatch.businessMeterCount }}</div>
						</div>
						<div
							class="label-item"
							@click="
								handleNumClick({
									userType: 5,
									queryDimension: 0,
									title: '考核表',
								})
							"
						>
							<div class="title">考核表数量</div>
							<div class="value">{{ bigWatch.assessmentMeterCount }}</div>
						</div>
						<div
							class="label-item"
							@click="
								handleNumClick({
									userType: 6,
									queryDimension: 0,
									title: '流量计',
								})
							"
						>
							<div class="title">流量计数量</div>
							<div class="value">{{ bigWatch.flowMeterCount }}</div>
						</div>
						<div
							class="label-item"
							@click="
								handleNumClick({
									queryDimension: 4,
									title: '远传大表-昨日未采集',
								})
							"
						>
							<div class="title">昨日未采集</div>
							<div class="value">{{ bigWatch.yesterdayNoUpCount }}</div>
						</div>
					</div>
				</div>
				<!-- 设备列表 -->
				<DevicesTable :waterRegionCode="ssqy" @change-code="handleChangeCode" />

				<!-- 设备厂商 -->
				<ManufacturerPie :waterRegionCode="ssqy" />
			</div>
			<div class="right-modal modal">
				<div class="statistic-card">
					<div class="left-icon">
						<img src="@/assets/imgs/icon/yuanchuanxiaobiao.svg" alt="" />
						<p class="cn-name">远传小表</p>
						<p class="en-name">Remote watch</p>
					</div>
					<div class="right-data">
						<div
							class="label-item"
							@click="
								handleNumClick({
									userType: 3,
									queryDimension: 0,
									title: '远传小表-水表数量',
								})
							"
						>
							<div class="title">水表数量</div>
							<div class="value">{{ smallWatch.meterCount }}</div>
						</div>
						<div
							class="label-item"
							@click="
								handleNumClick({
									queryDimension: 1,
									title: '远传小表-本月新增',
									queryDimension: 5,
									title: '采集器',
								})
							"
						>
							<div class="title">采集器</div>
							<div class="value">{{ smallWatch.collectorCount }}</div>
						</div>
						<div
							class="label-item"
							@click="
								handleNumClick({
									queryDimension: 2,
									title: '远传小表-昨日未采集',
								})
							"
						>
							<div class="title">昨日未采集</div>
							<div class="value">{{ smallWatch.yesterdayNoUpCount }}</div>
						</div>
						<div
							class="label-item"
							@click="
								handleNumClick({
									queryDimension: 3,
									title: '远传小表-上月未采集',
								})
							"
						>
							<div class="title">上月未采集</div>
							<div class="value">{{ smallWatch.lastMonthNoUpCount }}</div>
						</div>
					</div>
				</div>
				<WaterMeterLine :waterRegionCode="ssqy" />
			</div>
		</div>
		<WaterMeterTableList v-if="tableVisible" :currentRow="currentRow" @close-modal="tableVisible = false" />
	</div>
</template>

<script>
import DevicesTable from './components/DevicesTable.vue'
import ManufacturerPie from './components/ManufacturerPie.vue'
import WaterMeterLine from './components/WaterMeterLine.vue'
import WaterMeterTableList from './components/WaterMeterTableList'

import { apiGetSmallMeterStatistics, apiGetBigMeterStatistics } from '@/apis/home.api'
import { isBlank } from '@/utils/validate'
import { mapActions } from 'vuex'

export default {
	name: 'WaterHome',
	components: {
		DevicesTable,
		ManufacturerPie,
		WaterMeterLine,
		WaterMeterTableList,
	},
	data() {
		return {
			ssqy: undefined,
			pieInstance: null,
			bigWatch: {
				businessMeterCount: 0, //工商表数量
				assessmentMeterCount: 0, //考核表数量
				flowMeterCount: 0, //流量计数量
				yesterdayNoUpCount: 0, //昨日未采集
			},
			smallWatch: {
				meterCount: 0, //水表数量
				monthAddCount: 0, //本月新增
				yesterdayNoUpCount: 0, //昨日未采集
				lastMonthNoUpCount: 0, //上月未采集
			},
			collectorCount: 0,
			tableVisible: false,
			currentRow: {},
			disabledFn: data => {
				return data.unSelectFlag && data.unSelectFlag === 1
			},
		}
	},
	created() {
		this.getTreeOptions().then(() => {
			// const { waterRegionCode } = this.userInfo;
			// if (!waterRegionCode) return;
			// const codes = waterRegionCode.split(",");
			// let minLenCode = codes[0];
			// for (let i = 1; i < codes.length; i++) {
			//   if (minLenCode.length > codes[i].length) {
			//     minLenCode = codes[i];
			//   }
			// }
			this.ssqy = this.userInfo.defaultWaterRegionCode
		})
	},
	watch: {
		ssqy: {
			handler(val) {
				if (!isBlank(val)) {
					this.getSmallMeterStatistics()
					this.getBigMeterStatistics()
				}
			},
			deep: true,
			immediate: true,
		},
	},

	computed: {
		treeOptions() {
			return this.$store.getters.userRegionTree
		},
		userInfo() {
			return this.$store.state.user.userInfo
		},
	},
	methods: {
		...mapActions({
			getTreeOptions: 'user/getTreeOptions',
		}),

		handleData(data) {
			Object.entries(data).forEach(([key, value]) => {
				if (isBlank(value)) {
					data[key] = 0
				}
			})
			return data
		},
		// 远传小表数据统计
		getSmallMeterStatistics() {
			apiGetSmallMeterStatistics({
				waterRegionCode: this.ssqy,
			}).then(res => {
				this.smallWatch = this.handleData(res || {})
			})
		},
		// 远传大表数据统计
		getBigMeterStatistics() {
			apiGetBigMeterStatistics({
				waterRegionCode: this.ssqy,
			}).then(res => {
				this.bigWatch = this.handleData(res || {})
			})
		},
		handleChangeCode(code) {
			this.ssqy = code
		},
		handleNumClick(obj) {
			this.currentRow = {
				...obj,
				waterRegionCode: this.ssqy,
			}
			this.tableVisible = true
		},
	},
}
</script>
<style lang="scss" scoped>
.water-home-page {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 12px;
	overflow-y: scroll;
	.top-region-pick {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 12px;
	}
	.content {
		display: flex;
		justify-content: space-between;
		.modal {
			width: calc(50% - 8px);
			.statistic-card {
				background-color: #cbd8ff;
				border-radius: 12px;
				display: flex;
				justify-content: space-around;
				.left-icon {
					position: relative;
					top: -24px;
					padding-left: 36px;
					img {
						width: 140px;
					}
					.cn-name {
						color: #474d59;
						font-size: 24px;
						font-weight: 500;
						letter-spacing: 3px;
						line-height: 36px;
						padding-left: 16px;
						white-space: nowrap;
					}
					.en-name {
						color: rgb(131, 137, 154);
						font-size: 16px;
						line-height: 24px;
						letter-spacing: 1px;
						padding-left: 16px;
						white-space: nowrap;
					}
				}
				.right-data {
					display: flex;
					align-items: center;
					.label-item {
						text-align: center;
						margin: 12px 24px 0;
						cursor: pointer;
						.title {
							color: #3f435e;
							font-size: 20px;
							line-height: 30px;
						}
						.value {
							color: #4d6bff;
							font-weight: 700;
							line-height: 37px;
							font-size: 32px;
							margin-top: 12px;
							font-family: DIN-Regular-2;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 600px) and (max-width: 1400px) {
	.water-home-page {
		.content {
			.modal {
				.statistic-card {
					.left-icon {
						width: 100px;
						padding-left: 12px;
						img {
							width: 100px;
						}
						.cn-name {
							font-size: 18px;
						}
						.en-name {
							font-size: 12px;
						}
					}
					.right-data {
						.label-item {
							margin: 8px 12px 0;
							.title {
								font-size: 16px;
							}
							.value {
								font-size: 26px;
							}
						}
					}
				}
			}
		}
	}
}

::v-deep {
	.gc-blank-page {
		margin-top: 20px;
	}
}
</style>
