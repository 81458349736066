<template>
	<div class="water-home-manufacturer-pie">
		<div class="water-home-title">设备厂商</div>
		<div class="pie-modal" v-if="list.length > 0">
			<div class="charts"></div>
			<div class="info">
				<div class="table-header">
					<div class="th" v-for="(item, index) in columns" :key="index">
						<p :class="{ right: index > 0 }">{{ item.title }}</p>
					</div>
				</div>
				<div class="table-body">
					<div class="tr" v-for="(item, index) in list" :key="index">
						<div class="td" v-for="(el, idx) in columns" :key="idx">
							<p class="title" v-if="el.key === 'nanufacturerName'">
								<em class="icon" :style="{ background: colors[index % colors.length] }"></em>
								<span>{{ item[el.key] }}</span>
							</p>
							<p v-else class="right">{{ item[el.key] }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<gc-empty v-else />
	</div>
</template>

<script>
import { init } from 'echarts'
import { apiGetManufacturer } from '@/apis/home.api'
import { isBlank } from '@/utils/validate'

export default {
	props: {
		waterRegionCode: {
			type: [String, Number],
			default: '',
		},
	},
	data() {
		return {
			colors: ['#36B2F2', '#F9CB27', '#7A3FF8', '#2EE5DA', '#C0DF00', '#FF971D', '#7278FF', '#FFE500', '#77A5FF'],
			columns: [
				{ title: '设备厂商', key: 'nanufacturerName' },
				{ title: '设备数量', key: 'equipmentCount' },
				{ title: '占比', key: 'proportion' },
			],
			pieInstance: null,
			equipmentSumCount: 0,
			list: [],
		}
	},
	created() {},
	watch: {
		waterRegionCode(val) {
			if (!isBlank(val)) {
				this.getManufacturer()
			}
		},
	},

	methods: {
		packageChartOptions() {
			let seriesData = this.list.map(item => {
				return {
					value: item.equipmentCount,
					name: item.nanufacturerName,
				}
			})
			return {
				color: this.colors,
				title: {
					show: true,
					text: '设备数量',
					left: '47%',
					top: '40%',
					textAlign: 'center',
					textStyle: {
						color: '#7B7E97',
						fontSize: 14,
					},
					subtext: `${this.equipmentSumCount}`,
					subtextStyle: {
						color: '#3F435E',
						fontWeight: 700,
						fontSize: 18,
						fontFamily: 'DIN',
					},
				},
				series: [
					{
						type: 'pie',
						radius: ['60%', '75%'],
						itemStyle: {
							borderRadius: 2,
						},
						label: {
							show: false,
							position: 'center',
						},
						emphasis: {
							label: {
								show: false,
								fontSize: 12,
								fontWeight: 'bold',
							},
						},
						labelLine: {
							show: false,
						},
						data: seriesData,
					},
					{
						type: 'pie',
						radius: ['82%', '83%'],
						label: {
							show: false,
						},
						emphasis: {
							disabled: true,
						},
						labelLine: {
							show: false,
						},
						data: [
							{
								value: 1,
								itemStyle: {
									color: '#DCDCDC60',
								},
							},
						],
					},
					{
						type: 'pie',
						radius: ['40%', '41%'],
						label: {
							show: false,
						},
						emphasis: {
							disabled: true,
						},
						labelLine: {
							show: false,
						},
						data: [
							{
								value: 999999999,
								itemStyle: {
									color: '#DCDCDC60',
								},
							},
						],
					},
					{
						type: 'pie',
						radius: ['45%', '55%'],
						label: {
							show: false,
						},
						emphasis: {
							disabled: true,
						},
						labelLine: {
							show: false,
						},
						padAngle: 3,
						data: new Array(60).fill(null).map(() => {
							return {
								value: 10,
								itemStyle: {
									color: '#D0DDFE90',
								},
							}
						}),
					},
				],
			}
		},
		bindChartResize() {
			this.pieInstance?.resize()
		},
		getManufacturer() {
			apiGetManufacturer({
				waterRegionCode: this.waterRegionCode,
			}).then(res => {
				this.equipmentSumCount = res.equipmentSumCount
				this.list = res.list || []
				if (this.list.length > 0) {
					this.$nextTick(() => {
						if (!this.pieInstance) {
							this.pieInstance = init(document.querySelector('.charts'))
						}
						this.pieInstance.setOption(this.packageChartOptions())
						window.addEventListener('resize', this.bindChartResize)
					})
				} else {
					this.pieInstance = null
				}
			})
		},
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.bindChartResize)
	},
}
</script>
<style lang="scss" scoped>
.water-home-manufacturer-pie {
	.pie-modal {
		display: flex;
		padding: 12px;
		align-items: flex-start;
		.charts {
			width: 214px;
			height: 214px;
			margin-top: 24px;
		}
		.info {
			flex: 1;
			.table-header {
				display: flex;
				.th {
					padding: 12px;
					flex: 1;
					p {
						font-size: 16px;
						color: #3f435e;
						font-weight: 500;
						&.right {
							text-align: right;
						}
					}
				}
			}
			.table-body {
				.tr {
					display: flex;
					.td {
						flex: 1;
						padding: 12px;
						p {
							color: #3f435e;
							&.right {
								text-align: right;
								font-family: DIN-Regular-2;
								font-size: 16px;
								font-weight: 700;
							}
							span {
								font-size: 14px;
								margin-left: 6px;
							}
							.icon {
								width: 12px;
								height: 12px;
								border-radius: 3px;
								display: inline-block;
								position: relative;
								top: 1px;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 600px) and (max-width: 1400px) {
	.water-home-manufacturer-pie {
		.pie-modal {
			padding: 0 12px;
			.charts {
				width: 180px;
				height: 180px;
				margin-top: 12px;
			}
			.info {
				.table-header {
					.th {
						padding: 8px 12px;
						p {
							font-size: 15px;
						}
					}
				}
				.table-body {
					.tr {
						.td {
							padding: 7px 12px;
							p {
								&.right {
									font-size: 14px;
									font-weight: 700;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>
